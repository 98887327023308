import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { EffectFade, Lazy } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { useHash } from '../../hooks/useHash';
import FeedBack from '../FeedBack';
import FormController from '../FormController';
import { metrikaForm3 } from '../metrika';
import Modal from '../Modal';
import ModalThx from '../ModalThx';

import { selectSlider } from '../../reducers/topSliderSlice';

import { IMG_URL } from '../../constants';

import 'swiper/modules/effect-fade/effect-fade.scss';
import 'swiper/modules/lazy/lazy.scss';
import 'swiper/swiper.scss';
import styles from './TopSlider.module.scss';

const TopSlider = () => {
	const [hash, updateHash] = useHash();
	const [showFormId, setShowFormId] = useState(null);
	const slider = useSelector(selectSlider);
	const { main } = slider;
	const [ready, setReady] = useState(false);
	const ref = useRef(null);
	const timeout = useRef(null);

	const params = {
		loop: true,
		grabCursor: false,
		draggable: false,
		allowTouchMove: false,
		slidesPerView: 1,
		spaceBetween: 0,
		preventClicks: false,
		preventClicksPropagation: false,
		speed: 0,
		ref,
		modules: [Lazy, EffectFade],
		lazy: {
			loadPrevNext: true,
			loadPrevNextAmount: 1
		}
	};

	const handleCloseCheckIn = () => {
		setShowFormId(null);
	};

	useEffect(() => {
		const formId = hash.startsWith('#open_form_id=')
			? Number(hash.split('=')[1])
			: null;
		if (formId && !isNaN(formId)) {
			updateHash('');
			setShowFormId(formId);
		}
	}, [hash, updateHash]);

	useEffect(() => {
		if (ref.current !== null && ref.current.swiper !== null) {
			timeout.current = setInterval(() => {
				ref.current.swiper.slideNext();
			}, 10000);
		}

		setReady(true);

		return () => clearInterval(timeout.current);
	}, [ref]);

	return (
		<>
			{main.length > 0 && (
				<section className={styles.top}>
					<Swiper
						{...params}
						className={cn(styles.top__slider, styles.slider, {
							[styles.slider_ready]: ready
						})}
					>
						{main.map(({ order, X1, X2, X3, advertisement }) => {
							return (
								<SwiperSlide
									className={cn(styles.slider__slide, styles.slide)}
									key={order}
								>
									{advertisement && Object.keys(advertisement).length > 0 && (
										<div
											style={{
												backgroundColor: advertisement.background_color
											}}
											className={styles.slide__bannerContainer}
										>
											{advertisement.title && (
												<h3 className={styles.slide__bannerTitle}>
													{advertisement.title}
												</h3>
											)}
											{advertisement.subtitle && (
												<h4 className={styles.slide__bannerDesc}>
													{advertisement.subtitle}
												</h4>
											)}
											<div className={styles.slide__bannerButtons}>
												{advertisement.left_button &&
													advertisement.left_button.url && (
														// eslint-disable-next-line react/jsx-no-target-blank
														<a
															href={advertisement.left_button.url}
															target={
																advertisement.left_button.url.startsWith('http')
																	? '_blank'
																	: '_self'
															}
															rel={
																advertisement.left_button.url.startsWith('http')
																	? 'noopener noreferrer'
																	: ''
															}
															className={styles.slide__bannerButtonPrimary}
														>
															{advertisement.left_button.text || 'Оформить'}
														</a>
													)}
												{advertisement.right_button &&
													advertisement.right_button.url && (
														// eslint-disable-next-line react/jsx-no-target-blank
														<a
															href={advertisement.right_button.url}
															target={
																advertisement.right_button.url.startsWith(
																	'http'
																)
																	? '_blank'
																	: '_self'
															}
															rel={
																advertisement.right_button.url.startsWith(
																	'http'
																)
																	? 'noopener noreferrer'
																	: ''
															}
															className={styles.slide__bannerButtonSecondary}
														>
															{advertisement.right_button.text || 'Подробнее'}
														</a>
													)}
											</div>
										</div>
									)}

									<img
										data-src={X1}
										data-srcset={`${X2} 2x, ${X3} 3x`}
										alt=''
										className={cn(styles.slide__img, 'swiper-lazy')}
									/>
									<div className={styles.slide__bg}></div>
								</SwiperSlide>
							);
						})}
					</Swiper>
					<div className={styles.top__wrap}>
						<img
							src={`${IMG_URL}/icons/lucky.svg`}
							alt='Удачное'
							className={styles.top__lucky}
						/>
						<span className={cn('h4', styles.top__descr)}>
							апартаменты премиум-класса с&nbsp;видом на море
						</span>
						<span className={cn('h4', styles.top__law)}>ФЗ-214</span>
					</div>

					<div className={styles.top__location}>
						<span className='h4'>Крым</span>
						<span className='h4'>г.Алушта</span>
						<span className='h4'>п.Виноградное</span>
					</div>
				</section>
			)}
			{showFormId && (
				<Modal hide={handleCloseCheckIn}>
					<FormController>
						{(isShowForm, setShowThx) =>
							isShowForm ? (
								<FeedBack
									onSuccess={setShowThx}
									title='Записаться на просмотр'
									id={showFormId}
									ym={metrikaForm3}
								/>
							) : (
								<ModalThx hide={handleCloseCheckIn} />
							)
						}
					</FormController>
				</Modal>
			)}
		</>
	);
};

export default TopSlider;
