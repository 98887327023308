import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import App from './App';
import './assets/style/index.scss';
import { SITE_KEY } from './constants';
import store from './reducers';

function RedirectToHtmlSite() {
	// Перенаправление на HTML-сайт
	React.useEffect(() => {
		window.location.href = '/360/index.html';
	}, []);

	return null; // Этот компонент ничего не рендерит
}

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<GoogleReCaptchaProvider reCaptchaKey={SITE_KEY} language='ru'>
				<Provider store={store}>
					<Routes>
						<Route path='/' element={<App />} />
						<Route path='/panno' element={<RedirectToHtmlSite />} />
						<Route path='*' element={<App />} />
					</Routes>
				</Provider>
			</GoogleReCaptchaProvider>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);
